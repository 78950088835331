<template>
  <input
    type="checkbox"
    :checked="modelValue"
    :id="label"
    @change="$emit('update:modelValue', $event.target.checked)"
    class="field"
  />
  <label v-if="label" :for="label">{{ label }}</label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped>
input {
  height: 1.25em;
  width: 1.25em;
  margin-right: 0.5em;
}
label:not(:last-child) {
  margin-right: 1em;
}
</style>
