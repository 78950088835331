<template>
  <div class="about-page">
    <p>
      <a href="https://www.dnr.state.mn.us/state_parks/clubs.html"
        >Hiking club</a
      >
      trails in Minnesota, on a map!
    </p>
  </div>
</template>
