<template>
  <div class="trail-card">
    <h3>{{ trail.attributes.title }}</h3>
    <p>Trail length: {{ trail.attributes.field_mileage }} miles</p>
    <p>
      <a
        :href="
          'https://www.dnr.state.mn.us/state_parks/park.html?id=' +
          trail.attributes.field_mndnr_id.toLowerCase() +
          '#homepage'
        "
        >Park website</a
      >
      |
      <a
        :href="
          'https://maps.google.com/maps?daddr=' +
          trail.attributes.field_coordinates_point[1] +
          ',' +
          trail.attributes.field_coordinates_point[0]
        "
      >
        Directions
      </a>
    </p>
    <p>County: {{ trail.attributes.field_county }}</p>
  </div>
</template>

<script>
export default {
  name: 'TrailCard',
  props: {
    trail: Object
  }
}
</script>

<style scoped>
.trails .trail-card {
  padding: 20px;
  width: 350px;
  margin: 1em;
  margin-bottom: 18px;
  background: white;
}
.trails .trail-card:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2);
}
.trail-link {
  color: #2c3e50;
  text-decoration: none;
}
@media screen and (max-width: 767px) {
  .trails .trail-card {
    width: 90%;
  }
}
</style>
