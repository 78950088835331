<template>
  <header>
    <div class="wordmark">
      <h1>🌲 <a href="/">HikeMinnesota.com</a></h1>
      <h2>State park hiking club trail directory</h2>
    </div>
  </header>
  <router-view />
</template>

<style>
*,
*::before,
*::after {
  box-sizing: border-box;
}
:root {
  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);
}
body {
  background: #1b2431;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  margin: 0;
}
header {
  width: 90%;
  margin: 0 auto 3em;
}

h1 {
  color: #ffa62b;
  margin: 1em 0 0;
  font-size: 2em;
}
h1 a,
h1 a:visited {
  text-decoration: none;
  color: #ffa62b;
}
h2 {
  color: white;
  font-size: 1.25em;
  margin: 0;
}
.about-page {
  background: white;
  height: 60vh;
  width: 90vw;
  margin: 0 auto;
  padding: 1.5em 2em;
}
/* Tweaks for small-width */
@media screen and (max-width: 767px) {
  h1 {
    font-size: 1.75em;
  }
  h2 {
    font-size: 1em;
    margin-top: 4px;
    font-weight: normal;
  }
  header {
    margin-bottom: 2em;
  }
}
</style>
