<template>
  <div class="cols">
    <MapFilters @filters-updated="updateMarkers" />

    <div class="map">
      <GMapMap :center="center" :zoom="7" map-type-id="terrain">
        <GMapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          :draggable="false"
          @click="openMarker(m.id)"
          @closeclick="openMarker(null)"
        >
          <GMapInfoWindow
            :closeclick="true"
            @closeclick="openMarker(null)"
            :opened="openedMarkerID === m.id"
          >
            <div class="trail-info">
              <TrailCard :key="m.id" :trail="m.trailInfo" />
            </div>
          </GMapInfoWindow>
        </GMapMarker>
      </GMapMap>
    </div>
  </div>

  <div class="trails">
    <TrailCard
      v-for="marker in markers"
      :key="marker.id"
      :trail="marker.trailInfo"
    />
  </div>
</template>

<script>
import TrailCard from '@/components/TrailCard.vue'
import MapFilters from '@/components/MapFilters.vue'
import TrailService from '@/services/TrailService.js'

export default {
  name: 'SearchableMap',
  emits: ['markers-init'],
  components: {
    TrailCard,
    MapFilters
  },
  data() {
    return {
      markers: null,
      markersInit: null,
      openedMarkerID: null,
      center: { lat: 46.072649727023, lng: -92.56204874713444 }
    }
  },
  created() {
    TrailService.getTrails()
      .then((response) => {
        // Create map markers for each park in our API data.
        this.markers = this.getMarkers(response.data.data)
        this.markersInit = this.markers
        this.$emit('markers-init', this.markersInit)
        // Filter to our on-load defaults
        this.updateMarkers({
          length: '0-500',
          showCompleted: true,
          showNotCompleted: false
        })
      })
      .catch((error) => {
        console.log(error)
      })
  },
  methods: {
    // Create marker objects for the Google Map.
    getMarkers: function (data) {
      let markers = []
      for (let i = 0; i < data.length; i++) {
        markers[i] = {
          id: data[i].id,
          trailInfo: data[i],
          position: {
            lat: parseFloat(data[i].attributes.field_coordinates_point[1]),
            lng: parseFloat(data[i].attributes.field_coordinates_point[0])
          }
        }
      }
      return markers
    },
    openMarker(id) {
      this.openedMarkerID = id
    },
    updateMarkers(filters) {
      this.markers = this.markersInit // Reset our list.

      // Filter by trail length.
      let minLength = parseFloat(filters.length.split('-')[0])
      let maxLength = parseFloat(filters.length.split('-')[1])
      this.markers = this.markers.filter((marker) => {
        return (
          parseFloat(marker.trailInfo.attributes.field_mileage) >= minLength &&
          parseFloat(marker.trailInfo.attributes.field_mileage) <= maxLength
        )
      })

      // Filter by trail completion (user pages).
      if (this.$parent.user) {
        let completedHikes =
          this.$parent.user.relationships.field_completed_hikes.data

        // Drop completed hikes (has value in completedHikes)
        if (filters.showCompleted == false) {
          for (let i = 0; i < completedHikes.length; i++) {
            this.markers = this.markers.filter(
              (marker) => marker.id !== completedHikes[i].id
            )
          }
        }
        // Drop uncompleted hikes (is not in completedHikes)
        if (filters.showNotCompleted == false) {
          this.markers = this.markers.filter((marker) =>
            completedHikes.some((e) => e.id === marker.id)
          )
        }
      }
    }
  }
}
</script>

<style>
.cols {
  display: flex;
}
.trails {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.vue-map-container .vue-map {
  width: 70vw;
  margin: 0 auto 2em;
}
.trail-info {
  width: 200px;
}
.form-container {
  background: white;
  width: 30vw;
}
.vue-map-container .vue-map,
.form-container {
  height: 70vh;
  min-height: 400px;
}
/* Tweaks for small-width */
@media screen and (max-width: 1024px) {
  .cols {
    display: block;
  }
  .form-container,
  .vue-map-container .vue-map {
    width: 100vw;
    min-height: auto;
  }
  .form-container {
    height: auto;
    padding-bottom: 3em;
  }
}
</style>
