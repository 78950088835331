import { createRouter, createWebHistory } from 'vue-router'
import VueRouterMetaTags from '@bachdgvn/vue-router-meta-tags'
import SearchableMap from '../views/SearchableMap.vue'
import UserPage from '../views/UserPage.vue'
import AboutView from '../views/AboutView.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
  { path: '/404', name: 'NotFound', component: NotFound },
  { path: '/:catchAll(.*)', redirect: '/404' },
  {
    path: '/',
    name: 'SearchableMap',
    component: SearchableMap,
    meta: {
      title: 'Hike Minnesota',
      metaTags: [
        {
          name: 'description',
          content: 'Directory of Minnesota state park hiking club trails.'
        },
        {
          property: 'og:description',
          content: 'Directory of Minnesota state park hiking club trails.'
        }
      ]
    }
  },
  {
    path: '/about',
    name: 'AboutView',
    component: AboutView,
    meta: {
      title: 'About Hike Minnesota'
    }
  },
  {
    path: '/user/:username',
    name: 'UserPage',
    props: true,
    component: UserPage,
    meta: {
      title: 'Hike Minnesota'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(VueRouterMetaTags.update)

export default router
