import axios from 'axios'

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_DRUPAL_URL + '/jsonapi',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  getTrails() {
    return apiClient.get('/views/trails_listing/default')
  },
  getTrail(id) {
    return apiClient.get('/node/trail/' + id)
  },
  getUser(username) {
    return apiClient.get(
      '/views/user_trails/default?views-argument[0]=' + username
    )
  }
}
