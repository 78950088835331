<template>
  <div class="about-page">
    <h1>404 Not Found</h1>
    <p>🥾 <a href="/">Return to the homepage</a></p>
  </div>
</template>

<style scoped>
p {
  font-size: 1.5em;
}
a {
  text-decoration: none;
}
</style>
