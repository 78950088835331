<template>
  <div v-if="user" class="secondary-page">
    <div class="user-stats">
      <div class="stats-wrap">
        <div class="stat">
          <h4>Trails hiked:</h4>
          <span>{{ trailsHiked }}</span>
        </div>
        <div class="stat">
          <h4>Total miles:</h4>
          <span>{{ totalMiles }}</span>
        </div>
        <div class="stat">
          <h4>Miles til next badge:</h4>
          <span>{{ milesTilNextBadge }}</span>
        </div>
      </div>
    </div>

    <SearchableMap @markers-init="createUserFilters" />
  </div>
</template>

<script>
import TrailService from '@/services/TrailService.js'
import SearchableMap from '@/views/SearchableMap.vue'

export default {
  props: ['username'],
  components: {
    SearchableMap
  },
  data() {
    return {
      user: null,
      markers: null,
      mileage: 0
    }
  },
  created() {
    TrailService.getUser(this.username)
      .then((response) => {
        this.user = response.data.data[0]
        document.title = 'Hike Minnesota | ' + this.user.attributes.display_name
      })
      .catch((error) => {
        console.log(error)
      })
  },
  computed: {
    trailsHiked() {
      return this.user.relationships.field_completed_hikes.data.length
    },
    totalMiles() {
      let hikes = this.user.relationships.field_completed_hikes.data
      if (this.markers) {
        hikes.forEach((hike) => {
          this.mileage += parseFloat(
            this.markers.find((x) => x.id === hike.id).trailInfo.attributes
              .field_mileage
          )
        })
      }
      return this.mileage
    },
    milesTilNextBadge() {
      let milesToGo = 0
      if (this.mileage) {
        if (this.mileage < 25) {
          milesToGo = 25 - this.mileage
        }
        if (this.mileage >= 25 && this.mileage < 50) {
          milesToGo = 50 - this.mileage
        }
        if (this.mileage >= 50 && this.mileage < 75) {
          milesToGo = 75 - this.mileage
        }
        if (this.mileage >= 75 && this.mileage < 100) {
          milesToGo = 100 - this.mileage
        }
      }
      return milesToGo.toFixed(2)
    }
  },
  methods: {
    // Pull in markers from the SearchableMap component.
    createUserFilters(markers) {
      this.markers = markers
    }
  }
}
</script>

<style scoped>
h3 {
  font-size: 2.5em;
  margin: 0;
}
h4 {
  font-size: 1em;
  font-weight: bold;
  margin: 0 0.25em;
  display: inline;
}
.user-stats {
  background: #75b3e7;
  color: white;
  padding: 1.25em 5vw;
  text-align: center;
}
.stats-wrap {
  display: flex;
}
.stat {
  font-size: 1.5em;
  margin-right: 2em;
}
/* Tweaks for small-width */
@media screen and (max-width: 1024px) {
  .user-stats {
    text-align: left;
  }
  .stats-wrap {
    display: block;
  }
  .stat {
    margin: auto;
  }
  h4 {
    margin-right: 8px;
  }
  h4,
  .stat div {
    display: inline;
  }
  .stat div {
    margin-top: 0.25em;
  }
}
</style>
