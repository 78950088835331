<template>
  <div class="form-container">
    <form @change="onSubmit">
      <h3>{{ formHeader }}</h3>

      <BaseSelect
        :options="lengthOptions"
        v-model="filters.length"
        label="Trail length:"
      />

      <div v-if="this.$parent.$parent.user" class="boxes">
        <!-- Only show this field on User pages -->
        <h4>Show my hikes on map</h4>
        <BaseCheckbox v-model="filters.showCompleted" label="Completed" />
        <BaseCheckbox
          v-model="filters.showNotCompleted"
          label="Not Completed"
        />
      </div>

      <p>Hikes shown here are Minnesota State Park Hiking Club trails.</p>
      <p class="lil-nav">
        <span
          >🔎
          <a
            target="_blank"
            href="https://www.dnr.state.mn.us/state_parks/clubs.html"
            >What is Hiking Club?</a
          ></span
        >
        <span>
          🥾
          <a href="https://account.hikeminnesota.com/user/register"
            >Log your hikes</a
          >
        </span>
      </p>
    </form>
  </div>
</template>

<script>
import BaseSelect from '@/components/BaseSelect.vue'
import BaseCheckbox from '@/components/BaseCheckbox.vue'

export default {
  name: 'MapFilters',
  components: {
    BaseSelect,
    BaseCheckbox
  },
  data() {
    return {
      lengthOptions: [
        {
          '0-500': 'Any',
          '0-3': '0-3 miles',
          '3-5': '3-5 miles',
          '5-500': '5+ miles'
        }
      ],
      filters: {
        length: '0-500',
        showCompleted: true,
        showNotCompleted: false
      }
    }
  },
  methods: {
    onSubmit() {
      this.$emit('filters-updated', this.filters)
    }
  },
  computed: {
    formHeader() {
      if (this.$parent.$parent.user) {
        return `Filter ${this.$parent.$parent.user.attributes.name}'s hikes`
      }
      return 'Find your next hike'
    }
  }
}
</script>

<style>
form h4 {
  margin-bottom: 0;
}
form {
  padding: 1.5em 3vw 0 5vw;
}
form p {
  max-width: 420px;
  line-height: 1.4em;
  margin: 1em 0 0;
}
form p:first-of-type {
  margin-top: 2em;
}
input {
  margin: 1.5em 0 1em;
  padding: 0.75em;
}
.lil-nav span {
  display: block;
  margin-bottom: 0.5em;
}
.lil-nav a {
  text-decoration: none;
}
</style>
